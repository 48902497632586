// Global UI Action
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const OPEN_MENU = 'OPEN_MENU';
export const OPEN_SUBMENU = 'OPEN_SUBMENU';
export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_RANDOM_THEME = 'CHANGE_RANDOM_THEME';
export const CHANGE_MODE = 'CHANGE_MODE';
export const CHANGE_GRADIENT = 'CHANGE_GRADIENT';
export const CHANGE_DECO = 'CHANGE_DECO';
export const CHANGE_BG_POSITION = 'CHANGE_BG_POSITION';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_DIRECTION = 'CHANGE_DIRECTION';
export const LOAD_PAGE = 'LOAD_PAGE';

// Shops
export const FETCH_SHOP_DATA = 'FETCH_SHOP_DATA';
export const SEARCH_SHOP = 'SEARCH_SHOP';
export const SHOW_DETAIL_SHOP = 'SHOW_DETAIL_SHOP';
export const ADD_SHOP = 'ADD_SHOP';
export const UPDATE_SHOP = 'UPDATE_SHOP';

//approval options

export const APPROVAL_DATA_RESET = 'APPROVAL_DATA_RESET';

export const FETCH_APPROVAL_DATA = 'FETCH_APPROVAL_DATA';
export const FETCH_APPROVAL_DATA_APPROVED = 'FETCH_APPROVAL_DATA_APPROVED';
export const FETCH_APPROVAL_DATA_APPROVED_BALANCE = 'FETCH_APPROVAL_DATA_APPROVED_BALANCE';
export const FETCH_APPROVAL_DATA_APPROVING = "FETCH_APPROVAL_DATA_APPROVING";
//wallet connect 
export const HOME_CONNECT_WALLET_BEGIN = 'HOME_CONNECT_WALLET_BEGIN';
export const HOME_CONNECT_WALLET_RESET = 'HOME_CONNECT_WALLET_RESET';
export const HOME_CONNECT_WALLET_SUCCESS = 'HOME_CONNECT_WALLET_SUCCESS';
export const HOME_CONNECT_WALLET_FAILURE = 'HOME_CONNECT_WALLET_FAILURE';
export const HOME_ACCOUNTS_CHANGED = 'HOME_ACCOUNTS_CHANGED';
export const HOME_NETWORK_CHANGED = 'HOME_NETWORK_CHANGED';

export const HOME_DISCONNECT_WALLET_BEGIN = 'HOME_DISCONNECT_WALLET_BEGIN';
export const HOME_DISCONNECT_WALLET_SUCCESS = 'HOME_DISCONNECT_WALLET_SUCCESS';
export const HOME_DISCONNECT_WALLET_FAILURE = 'HOME_DISCONNECT_WALLET_FAILURE';

// valuts
export const VAULT_FETCH_VAULTS_DATA_BEGIN = 'VAULT_FETCH_VAULTS_DATA_BEGIN';
export const VAULT_FETCH_VAULTS_DATA_SUCCESS = 'VAULT_FETCH_VAULTS_DATA_SUCCESS';
export const VAULT_FETCH_VAULTS_DATA_FAILURE = 'VAULT_FETCH_VAULTS_DATA_FAILURE';

export const VAULT_FETCH_BALANCES_BEGIN = 'VAULT_FETCH_BALANCES_BEGIN';
export const VAULT_FETCH_BALANCES_SUCCESS = 'VAULT_FETCH_BALANCES_SUCCESS';
export const VAULT_FETCH_BALANCES_FAILURE = 'VAULT_FETCH_BALANCES_FAILURE';

export const VAULT_FETCH_APPROVAL_BEGIN = 'VAULT_FETCH_APPROVAL_BEGIN';
export const VAULT_FETCH_APPROVAL_SUCCESS = 'VAULT_FETCH_APPROVAL_SUCCESS';
export const VAULT_FETCH_APPROVAL_FAILURE = 'VAULT_FETCH_APPROVAL_FAILURE';

export const VAULT_FETCH_DEPOSIT_BEGIN = 'VAULT_FETCH_DEPOSIT_BEGIN';
export const VAULT_FETCH_DEPOSIT_SUCCESS = 'VAULT_FETCH_DEPOSIT_SUCCESS';
export const VAULT_FETCH_DEPOSIT_FAILURE = 'VAULT_FETCH_DEPOSIT_FAILURE';

export const VAULT_FETCH_WITHDRAW_BEGIN = 'VAULT_FETCH_WITHDRAW_BEGIN';
export const VAULT_FETCH_WITHDRAW_SUCCESS = 'VAULT_FETCH_WITHDRAW_SUCCESS';
export const VAULT_FETCH_WITHDRAW_FAILURE = 'VAULT_FETCH_WITHDRAW_FAILURE';


export const VAULT_FETCH_STRATEGY_HARVEST_BEGIN = 'VAULT_FETCH_STRATEGY_HARVEST_BEGIN';
export const VAULT_FETCH_STRATEGY_HARVEST_SUCCESS = 'VAULT_FETCH_STRATEGY_HARVEST_SUCCESS';
export const VAULT_FETCH_STRATEGY_HARVEST_FAILURE = 'VAULT_FETCH_STRATEGY_HARVEST_FAILURE';

export const VAULT_FETCH_APYS_BEGIN = 'VAULT_FETCH_APYS_BEGIN';
export const VAULT_FETCH_APYS_SUCCESS = 'VAULT_FETCH_APYS_SUCCESS';
export const VAULT_FETCH_APYS_FAILURE = 'VAULT_FETCH_APYS_FAILURE';

//common 

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const SAVE_TXNS = 'SAVE_TXNS';
export const UPDATE_TXNS = 'UPDATE_TXNS';

// Toast Message
export const CLOSE_TOAST = 'CLOSE_TOAST';
export const OPEN_TOAST = 'OPEN_TOAST';


// stats
export const STAT_FETCH_VAULTS_DATA_BEGIN = 'STAT_FETCH_VAULTS_DATA_BEGIN';
export const STAT_FETCH_VAULTS_DATA_SUCCESS = 'STAT_FETCH_VAULTS_DATA_SUCCESS';
export const STAT_FETCH_VAULTS_DATA_FAILURE = 'STAT_FETCH_VAULTS_DATA_FAILURE';


